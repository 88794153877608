<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> User </strong> Information </CCardHeader>
            <CCardBody>
              <CForm>
                <!-- <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Is Main Leader?
                  </CCol>
                  <CCol sm="9">
                    <CSwitch
                      class="mr-1"
                      color="primary"
                      :checked.sync="isMainLeader"
                      label-on="YES"
                      label-off="NO"
                      @change.native="onIsMainLeaderChanged"
                    />
                  </CCol>
                </CRow>
                <CInput
                  label="Leader"
                  horizontal
                  readonly
                  v-model="selectedLeader.fullName"
                  v-if="!isMainLeader"
                >
                  <template #append>
                    <CButton color="primary" @click="onSearchLeader()">
                      Search
                    </CButton>
                  </template>
                </CInput> -->
                <CInput
                  label="Name"
                  horizontal
                  v-model="$v.obj.fullName.$model"
                  :isValid="checkIfValid('fullName')"
                  invalidFeedback="This is a required field and must be at least 2 characters"
                />
                <!-- <CInput label="Role" horizontal v-model="obj.role" readonly /> -->
                <CSelect
                  label="Role"
                  horizontal
                  :options="roles"
                  :value.sync="selectedRoleId"
                  @change="onRoleSelected()"
                >
                </CSelect>

                <CInput
                  label="Email"
                  horizontal
                  v-model="$v.obj.email.$model"
                  :isValid="checkIfValid('email')"
                  invalidFeedback="This is a required field and must be valid e-mail address"
                  :readonly="isEmailReadOnly"
                />
                <CInput
                  label="Phone"
                  horizontal
                  v-model="$v.obj.phone.$model"
                  :isValid="checkIfValid('phone')"
                  invalidFeedback="This is a required field, must be valid numbers only, and between 8 to 11 digits"
                />
                <!-- <CInput
                  label="MyKad No"
                  horizontal
                  v-model="$v.obj.myKad.$model"
                  :isValid="checkIfValid('myKad')"
                  invalidFeedback="This is a required field"
                /> -->
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Is Reset Password?
                  </CCol>
                  <CCol sm="9">
                    <CSwitch
                      class="mr-1"
                      color="primary"
                      :checked.sync="isResetPassword"
                    />
                  </CCol>
                </CRow>
                <CInput
                  description="Please specifiy password. Minimum password is 8 character with combonation of capital letter, numbers and special chracter."
                  label="Password"
                  horizontal
                  autocomplete="password"
                  v-model="plainPassword"
                  v-if="isResetPassword"
                />
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Is Enable?
                  </CCol>
                  <CCol sm="9">
                    <CSwitch
                      class="mr-1"
                      color="primary"
                      :checked.sync="obj.isEnable"
                    />
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="!isValid || submitted"
                @click="submit"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >

              <CButton
                class="ml-1"
                color="success"
                :disabled="isValid"
                @click="validate"
              >
                Validate
              </CButton>
              <CButton
                class="ml-1"
                color="danger"
                :disabled="!isDirty"
                @click="reset"
              >
                Reset
              </CButton>
              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <div>
      <CModal
        title="Search for Leader"
        :show.sync="leaderSearchPopup"
        size="xl"
      >
        <CRow>
          <CCol sm="12">
            <CCard>
              <!-- <CCardHeader> <strong> Campaign </strong> List </CCardHeader> -->
              <CCardBody>
                <CDataTable
                  :items="leaderList"
                  :fields="leaderFieldList"
                  column-filter
                  items-per-page-select
                  :items-per-page="10"
                  hover
                  sorter
                  pagination
                  table-filter
                  cleaner
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onLeaderSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
              <CCardFooter> </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>
  </div>
</template>

<script>
import ProfileApi from "../../lib/profileApi";
import RoleApi from "../../lib/roleApi";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
  numeric,
  decimal,
  maxLength,
} from "vuelidate/lib/validators";

const leaderList = [];
const leaderFieldList = [
  // { key: "id", _style: "min-width:50px" },
  { key: "fullName", _style: "min-width:200px;" },
  { key: "email", _style: "min-width:200px;" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const packageList = [];
const packageFieldList = [
  { key: "name", _style: "min-width:200px;" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const profilePackageList = [];
const profilePackageFieldList = [
  {
    key: "packageName",
    label: "Name",
  },
  {
    key: "packageApplicationType",
  },

  {
    key: "commisionType",
    label: "Commission Type",
  },
  {
    key: "commisionPercentage",
    label: "Percentage",
  },
  {
    key: "commisionAmount",
    label: "Commision Amount",
  },
  {
    key: "show_remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "User",
  data: () => {
    return {
      roleApi: new RoleApi(),
      roles: [],
      selectedRoleId: null,

      isMainLeader: true,
      editCommisionPopup: false,
      searchPackagePopup: false,
      newPackageProfile: {
        packageId: "",
        package: {
          name: "",
        },
        commisionAmount: 0,
      },
      isResetPassword: false,
      plainPassword: "",
      defaultCommisionPercentage: 300,
      isEmailReadOnly: false,
      leaderSearchPopup: false,
      leaderList: leaderList.map((item, id) => {
        return { ...item, id };
      }),
      leaderFieldList,
      selectedLeader: {
        id: null,
        fullName: "",
      },
      packageList: packageList.map((item, id) => {
        return { ...item, id };
      }),
      packageFieldList,
      selectedPackage: {
        id: null,
        name: "",
      },

      profilePackageList: profilePackageList.map((item, index) => {
        return { ...item, index };
      }),
      profilePackageFieldList,
      packageProfileAddPopup: false,
      infoList: [],
      api: new ProfileApi(),
      obj: {
        leaderId: null,
        fullName: "",
        role: "Sales",
        email: "",
        phone: "",
        myKad: "",
        profilePackages: [],
      },
      loading: false,
      submitted: false,
    };
  },
  mounted() {
    var self = this;
    self.resetObj();
    self.refreshRoles();
  },
  validations: {
    obj: {
      fullName: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      role: {
        required,
      },
      phone: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11),
      },
    },
  },
  computed: {
    computedProfilePackageList() {
      return this.profilePackageList.map((item) => {
        return {
          ...item,
          packageApplicationType: this.getPackageApplicationType(item),
          packageName: this.getPackageName(item),
          commisionType: this.getCommisionType(item),
          commisionPercentage: this.getCommisionPercentage(item),
        };
      });
    },

    afterCommision() {
      try {
        return (
          this.newPackageProfile.leaderCommisionAmount -
          this.newPackageProfile.commisionAmount
        );
      } catch (error) {}
    },
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },
  methods: {
    onRoleSelected() {
      var self = this;
      const role = self.roles.find((role) => role.value == self.selectedRoleId);
      self.obj.role = role.label;
    },
    refreshRoles() {
      var self = this;
      self.loading = false;
      self.roleApi
        .getList()
        .then((response) => {
          console.log(response.result);
          for (let i = 0; i < response.result.length; i++) {
            self.roles.push({
              value: response.result[i].id,
              label: response.result[i].name,
            });

            // if (response.result[i].name == "Draft") {
            //   self.selectedStockStatusId = response.result[i].id;
            // }
          }
          if (self.obj) {
            console.log("search role", self.obj.role);
            const role = self.roles.find((role) => role.label == self.obj.role);
            console.log(role);

            self.selectedRoleId = role.value;
          }
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    onIsMainLeaderChanged() {
      if (this.isMainLeader == false) {
        this.selectedLeader = {
          id: null,
          fullName: "",
        };
        this.obj.leaderId = null;

        this.defaultCommisionPercentage = 50;
      } else {
        this.defaultCommisionPercentage = 300;
      }
    },
    getCommisionDescription(item) {
      try {
        console.log("getCommisionDescription", item);
        if (this.isTopLeader()) {
          return (
            "Top leader commision " +
            item.commisionPercentage +
            "% (RM" +
            item.commisionAmount +
            ")"
          );
        } else {
          return (
            "Subagent commision " +
            item.commisionPercentage +
            "% (RM" +
            item.commisionAmount +
            ")" +
            ". Your commision " +
            this.getLeaderCommisionInPercentage(item) +
            "%, before = RM" +
            this.getLeaderCommision(item) +
            ", after = RM " +
            this.getLeaderAfterCommision(item) +
            ""
          );
        }
      } catch (error) {}
      return "Cannot determine your commision";
    },
    isTopLeader() {
      console.log("isTopLeader", this.obj.leader);
      if (this.obj.leader) return false;
      return true;
    },
    getLeaderCommisionInPercentage(item) {
      try {
        for (var i = 0; this.obj.leader.profilePackages.length; i++) {
          if (item.packageId == this.obj.leader.profilePackages[i].packageId) {
            return this.obj.leader.profilePackages[i].commisionPercentage;
          }
        }
      } catch (error) {}

      return 0;
    },
    getBasedCommision(item) {
      try {
        for (var i = 0; this.packageList.length; i++) {
          if (item.packageId == this.packageList[i].id) {
            return this.packageList[i].commisionAmount;
          }
        }
      } catch (error) {}

      return 0;
    },

    getLeaderCommision(item) {
      try {
        for (var i = 0; this.obj.leader.profilePackages.length; i++) {
          if (item.packageId == this.obj.leader.profilePackages[i].packageId) {
            return this.obj.leader.profilePackages[i].commisionAmount;
          }
        }
      } catch (error) {}

      return 0;
    },

    getLeaderAfterCommision(item) {
      return this.getLeaderCommision(item) - item.commisionAmount;
    },
    onRemovePackage(item) {
      var self = this;
      if (self.profilePackageList != null) {
        for (var i = 0; i < self.profilePackageList.length; i++) {
          if (self.profilePackageList[i].packageId === item.packageId) {
            self.profilePackageList.splice(i, 1);
          }
        }
      }
    },
    updateCommisionAmountFor(item) {
      if (item.commisionType == "By Percentage") {
        if (isNaN(item.commisionPercentage) == false) {
          var parentCommision = 0;
          if (this.obj.leaderId) {
            parentCommision = this.getLeaderCommision(item);
          } else {
            parentCommision = this.getBasedCommision(item);
          }
          item.commisionAmount =
            (parentCommision * item.commisionPercentage) / 100;
        }
      }
    },
    updateCommisionAmount() {
      if (this.newPackageProfile.commisionType == "By Percentage") {
        if (isNaN(this.newPackageProfile.commisionPercentage) == false) {
          this.newPackageProfile.commisionAmount =
            (this.newPackageProfile.leaderCommisionAmount *
              this.newPackageProfile.commisionPercentage) /
            100;
        }
      }
    },
    isCommisionPercentage(item) {
      if (item.commisionType == "By Percentage") return true;
      return false;
    },
    getCommisionPercentage(item) {
      if (item.commisionType == "By Percentage")
        return item.commisionPercentage;
      return "N/A";
    },
    getCommisionType(item) {
      if (item.commisionType == null) return "N/A";
      if (item.commisionType == "") return "N/A";
      return item.commisionType;
    },
    getPackageApplicationType(item) {
      if (item.package == null) return "N/A";
      if (item.package.applicationType == null) return "N/A";
      return item.package.applicationType;
    },
    getPackageName(item) {
      if (item.package == null) return "N/A";
      if (item.package.name == null) return "N/A";
      return item.package.name;
    },
    onSubmit() {
      var self = this;
      var profileDto = {
        profile: self.obj,
        isResetPassword: self.isResetPassword,
        plainPassword: self.plainPassword,
      };

      if(self.obj.role == "")
      self.obj.role = "Sales";
      if (!self.obj.id) {
        

        this.api
          .createProfileAppUser(profileDto)
          .then((response) => {
            self.$router.push({ path: "/admins/UserList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .updateProfileAppUser(profileDto)
          .then((response) => {
            self.$router.push({ path: "/admins/UserList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    onReset(evt) {
      evt.preventDefault();
      this.obj = {};
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetObj() {
      var self = this;
      if (self.$route.params.id) {
        this.api
          .getProfile(self.$route.params.id)
          .then((response) => {
            self.obj = response.result;
            console.log(self.obj);
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        self.obj = self.getEmptyObj();
        self.isResetPassword = true;
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];

      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    validate() {
      this.$v.$touch();
    },
    reset() {
      this.resetObj();
      this.submitted = false;
      this.$v.$reset();
    },
    getEmptyObj() {
      return {
        fullName: "",
        email: "",
        phone: "",
        role: "",
      };
      
    },
    submit() {
      if (this.isValid) {
        this.onSubmit();
        this.submitted = true;
      }
    },
    cancel() {
      this.$router.push({ path: "/admins/UserList" });
    },
  },
};
</script>
